import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;
import CrmAccess = EmployeeAPI.CrmAccess.CrmAccess;
import { NotifyError } from "@/helpers/Toast";
import store from "@/store";

export class SwaggerRepository extends BaseRepository {
  constructor() {
    super(ClientType.Reporting);
  }

  public Url(): string {
    const jwt = store.getters["user/GET_JWT"];
    return `${this.baseURL}/documentation/v1/swagger.json?token=${jwt}`;
  }
 }