export default function (basePath: string) {
    return [
        {
            path: `${basePath}/find/acode`,
            name: 'candidate-find-acode',
            component: () => import('@/apps/candidate-api/pages/Find/FindByAcode/index.vue'),
            meta: {
                app: ['candidate', 'candidate-archive'],
                pageTitle: 'Find By Acode',
                REQUIRES_ROLES: ["candidate:find"],
                breadcrumb: [
                    {
                        text: 'Find By Acode',
                        active: true,
                    },
                ],
                REQUIRES_USER_AUTHORISATION: true
            },
        }
    ]
}