import {AuthRepository} from "./auth";
import {UserRepository} from "./user";
import {ActivityLogRepository} from "@/apps/employee-api/api/activitylog";
import {AzureRepository} from "@/apps/employee-api/api/azure";
import {CrmAccessRepository} from "@/apps/employee-api/api/crmaccess";
import {MaintenanceRepository} from "@/apps/employee-api/api/maintenance";
import {SwaggerRepository} from "@/apps/employee-api/api/swagger";

export default {
    auth: new AuthRepository(),
    user: new UserRepository(),
    activitylog: new ActivityLogRepository(),
    azure: new AzureRepository(),
    crmaccess: new CrmAccessRepository(),
    maintenance: new MaintenanceRepository(),
    swagger: new SwaggerRepository(),
}