export default function (basePath: string) {
    return [
        {
            path: `${basePath}/vincere/callback`,
            name: "maintenance-vincerecallback",
            component: () =>
                import("@/apps/maintenance-api/pages/Vincere/Callback/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Vincere Callback",
                REQUIRES_ROLES: ["maintenance:vincere"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/vincereinstances`,
            name: "maintenance-vincereinstances",
            component: () =>
                import("@/apps/maintenance-api/pages/Vincere/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Vincere Instances",
                breadcrumb: [
                    {
                        text: "Vincere Instances",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:vincereinstances"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/vincereinstance/:identifier/:action(edit)?`,
            name: "maintenance-vincereinstance",
            component: () =>
                import("@/apps/maintenance-api/pages/Vincere/single/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Vincere Instance",
                breadcrumb: [
                    {
                        text: "Vincere Instances",
                        to: {name: "maintenance-vincereinstances"},
                        active: false,
                    },
                    {
                        text: "Vincere Instance",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:vincereinstances"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
