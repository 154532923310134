import Vue from "vue";
import $api from "@/repositories";
import $router from "@/router";
import User = EmployeeAPI.User.User;

export default {
  namespaced: true,
  state: {
    jwt: localStorage.getItem("jwt") ?? null,
    user: {
      id: null,
      azureId: null,
      firstName: null,
      lastName: null,
      email: null,
    },
    userscopes: [],
    currentApp: null,
    selected_location: null,
  },
  getters: {
    GET_JWT: (state): string => state.jwt,
    GET_USER: (state): any => state.user,
    IS_USER_LOGGED_IN: (state): boolean => state.jwt !== null,
    IS_USER_LOGGING_IN: (state): boolean => state.jwt === "logging in",
    GET_CURRENT_APP: (state): string => state.currentApp,
    GET_USER_SCOPES: (state): string[] => state.userscopes,
  },
  mutations: {
    SET_JWT(state: any, val: string) {
      state.jwt = val;
      localStorage.setItem("jwt", val);
    },
    SET_USER(state: any, val: any) {
      state.user = val;
    },
    SET_USER_SCOPES(state: any, val: string[]) {
      state.userscopes = val;
    },
    SET_APP(state: any, val: any) {
      if (val == null) return (state.currentApp = null);

      state.currentApp = val.app;
      this.commit("appConfig/SET_NAVBAR_COLOR", val.color);
    },
    LOGOUT(state: any) {
      const msal = this._vm.$msal;
      //if (state.jwt != null) msal.signOut();

      state.jwt = null;
      state.user = {
        id: null,
        azureId: null,
        firstName: null,
        lastName: null,
        email: null,
      };
      state.currentApp = null;
      localStorage.removeItem("jwt");
      localStorage.removeItem(
        `msal.${process.env.VUE_APP_MSAL_CLIENT_ID}.client.info`
      );
      localStorage.removeItem(
        `msal.${process.env.VUE_APP_MSAL_CLIENT_ID}.idtoken`
      );
      localStorage.clear();
    },
  },
  actions: {
    async LOGIN_CHECK({ state, commit }) {
      if (state.jwt != null) return;
      // @ts-ignore
      const msal = this._vm.$msal;
      if (msal.isAuthenticated()) {
        commit("SET_JWT", "logging in");

        const token = await msal.acquireToken({
          scopes: ["openid", "profile", "User.Read", "email"],
        });
        const login = await $api.employee.auth.AdLogin(token.accessToken);
        if (login != null) {
          commit("SET_JWT", login.token);
          commit("SET_USER", login.user);
          $router.push({ name: "home" }).catch(() => {
            //
          });
        }
      } else {
        commit("LOGOUT");
      }
    },
    async Me({ state, commit }) {
      if (state.jwt != null) return;
      const user: User = await $api.employee.user.Me();
      commit("SET_USER", user);
      const scopes: string[] = await $api.employee.auth.MeScopes();
      commit("SET_USER_SCOPES", scopes);
    },
    async GetMeScopes({ state, commit }) {
      const scopes: string[] = await $api.employee.auth.MeScopes();
      commit("SET_USER_SCOPES", scopes);
    },
  },
};
