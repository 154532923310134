import { BaseRepository } from "./BaseRepository";
import { AxiosResponse } from "axios";
import { NotifyError } from "@/helpers/Toast";
import ResponseObject = RepositoryTypes.ResponseObject;

export class DashboardRepository extends BaseRepository {
  public async Counts(): Promise<CandidateAPI.Dashboard.Counts> {
    try {
      const req: AxiosResponse<ResponseObject<CandidateAPI.Dashboard.Counts>> =
        await this._client.get(`dashboard/counts`);

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return null;
  }
}
