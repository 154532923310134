import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;
import CrmAccess = EmployeeAPI.CrmAccess.CrmAccess;
import { NotifyError } from "@/helpers/Toast";

export class CrmAccessRepository extends BaseRepository {
  constructor() {
    super(ClientType.Employee);
  }

  public async GetList(userId: string, refetch = false): Promise<CrmAccess[]> {
    try {
      const req: AxiosResponse<ResponseObject<CrmAccess[]>> =
        await this._client.get(`user/${userId}/crmaccess`, {
          params: { refetch },
        });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return [];
  }
}
