import Vue from "vue";
import msal from 'vue-msal'

Vue.use(msal, {
    auth: {
        clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
        tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
        redirectUri: `${window.location.origin}/login`
    },
    cache: {
        cacheLocation: 'localStorage'
    }
});