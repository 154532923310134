export default function (basePath: string) {
    return [
        {
            path: `${basePath}/roles`,
            name: "maintenance-roles",
            component: () =>
                import("@/apps/maintenance-api/pages/Roles/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Roles",
                breadcrumb: [
                    {
                        text: "Roles",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:role"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/role/:identifier/:action(edit)?`,
            name: "maintenance-role",
            component: () =>
                import("@/apps/maintenance-api/pages/Roles/single/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Role",
                breadcrumb: [
                    {
                        text: "Roles",
                        to: {name: "maintenance-roles"},
                        active: false,
                    },
                    {
                        text: "Role",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:role"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
