import store from "@/store";
import { AxiosResponse } from "axios";
import LoginResponse = EmployeeAPI.AuthRepository.LoginResponse;
import LoginRequest = EmployeeAPI.AuthRepository.LoginRequest;
import ResponseObject = RepositoryTypes.ResponseObject;
import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { NotifyError } from "@/helpers/Toast";

export class AuthRepository extends BaseRepository {
  constructor() {
    super(ClientType.Employee);
  }

  public async AdLogin(token: string): Promise<LoginResponse> {
    try {
      const jwt = store.getters["user/GET_JWT"];
      if (jwt == null && jwt == "logging in") return;

      const reqData: LoginRequest = {
        clientId: process.env.VUE_APP_CLIENT_ID,
        accessToken: token,
      };

      const req: AxiosResponse<ResponseObject<LoginResponse>> =
        await this._client.post("auth/ad-login", reqData);

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return null;
  }

  public async MeScopes(): Promise<string[]> {
    try {
      const req: AxiosResponse<ResponseObject<string[]>> =
        await this._client.get("auth/me/scopes");

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return [];
  }
}
