export default function (basePath: string) {
    return [
        {
            path: `${basePath}/bulk/send`,
            name: "registration-bulksend",
            component: () =>
                import("@/apps/registrations-api/pages/BulkSend/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Bulk Send",
                breadcrumb: [
                    {
                        text: "Bulk Send",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:bulksend"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
