import $store from "@/store";

export default function (rolesRequired: Array<string> = []): boolean {
    const required = [...rolesRequired]
    for (let i = 0; i < required.length; i++) {
        if(!required[i].startsWith("$"))
            required[i] = `${process.env.VUE_APP_SCOPE_PLATFORM}:${required[i]}`
        else
            required[i] = required[i].replace("$", "");
    }


    if (required.length === 0) {
        return true
    }

    const $currentScopes = $store.getters['user/GET_USER_SCOPES'];

    if ($currentScopes.includes("full_access"))
        return true;


    const requiredFullAccessApplications = [];
    for (const role of required) {
        const roleSplit = role.split(":");
        if (roleSplit.length == 2)
            requiredFullAccessApplications.push(`${roleSplit[0]}:full_access`);
        if (roleSplit.length == 3) {
            requiredFullAccessApplications.push(`${roleSplit[0]}}:full_access`);
            requiredFullAccessApplications.push(`${roleSplit[0]}:${roleSplit[1]}:full_access`);
        }
        if (roleSplit.length == 4) {
            requiredFullAccessApplications.push(`${roleSplit[0]}:full_access`);
            requiredFullAccessApplications.push(`${roleSplit[0]}:${roleSplit[1]}:full_access`);
            requiredFullAccessApplications.push(`${roleSplit[0]}:${roleSplit[1]}:${roleSplit[2]}:full_access`);
        } else if (roleSplit[0] == process.env.VUE_APP_SCOPE_PLATFORM) {
            //
        } else
            requiredFullAccessApplications.push(roleSplit[0]);
    }

    if (requiredFullAccessApplications.some(x => $currentScopes.includes(x))) {
        return true;
    }

    return required.every(v => $currentScopes.includes(v))
}
