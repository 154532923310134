import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import PaginationResponse = RepositoryTypes.PaginationResponse;
import ResponseObject = RepositoryTypes.ResponseObject;
import ActivityLog = EmployeeAPI.ActivityLog.ActivityLog;
import { NotifyError } from "@/helpers/Toast";

export class ActivityLogRepository extends BaseRepository {
  constructor() {
    super(ClientType.Employee);
  }

  public async GetList(
    userId: string,
    page = 1,
    limit = 10
  ): Promise<PaginationResponse<ActivityLog>> {
    try {
      const req: AxiosResponse<
        ResponseObject<PaginationResponse<ActivityLog>>
      > = await this._client.get(`user/${userId}/activitylog`, {
        params: {
          page,
          limit,
        },
      });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return { items: [], totalItems: 0 };
  }
}
