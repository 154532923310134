import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import { NotifyError } from "@/helpers/Toast";

import ResponseObject = RepositoryTypes.ResponseObject;
import Base = RegistrationsAPI.Maintenance;
export class MaintenanceRepository extends BaseRepository {
    constructor() {
        super(ClientType.Registrations);
    }

    public async assignEmployee(registrationId: string, employeeUmbracoId: string): Promise<boolean> {
        try {
            const req = await this._client.post(`maintenance/registration/${registrationId}/assign-employee`, {
                employeeUmbracoId
            });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async assignAllRegistrationsFromEmployee(oldEmployee: string, newEmployeeId: string) : Promise<Base.AssignAllRegistrationsResponse>
    {
        try {
            const req = await this._client.post(`maintenance/employee/${oldEmployee}/reassign-registrations`, {
                newEmployeeId
            });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }


    public async changeStatus(registrationId: string, statusId: number): Promise<boolean> {
        try {
            const req = await this._client.post(`maintenance/registration/${registrationId}/change-status`, {
                statusId
            });

            if (req.data.success) {
                return req.data.data;
            }
        }
        catch (e) {
            NotifyError(e.messages);
        }

        return false;
    }

    public async reindex(registrationId: string): Promise<boolean> {
        try {
            const req = await this._client.post(`maintenance/registration/${registrationId}/reindex`);

            if (req.data.success) {
                return req.data.data;
            }
        }
        catch (e) {
            NotifyError(e.messages);
        }

        return false;
    }

    public async reindexByCandidateId(candidateId: string): Promise<boolean> {
        try {
            const req = await this._client.post(`maintenance/candidate/${candidateId}/registrations/reindex`);

            if (req.data.success) {
                return req.data.data;
            }
        }
        catch (e) {
            NotifyError(e.messages);
        }

        return false;
    }
}
