export default function (basePath: string) {
    return [
        {
            path: `${basePath}/assignemployee`,
            name: "registration-maintenance-assignemployee",
            component: () =>
                import("@/apps/registrations-api/pages/Maintenance/AssignEmployee/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Assign Employee",
                breadcrumb: [
                    {
                        text: "Assign Employee",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:maintenance:assignemployee"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/statuschange`,
            name: "registration-maintenance-statuschange",
            component: () =>
                import("@/apps/registrations-api/pages/Maintenance/StatusChange/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Status Change",
                breadcrumb: [
                    {
                        text: "Status Change",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:maintenance:statuschange"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/reindex`,
            name: "registration-maintenance-reindex",
            component: () =>
                import("@/apps/registrations-api/pages/Maintenance/Reindex/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Reindex",
                breadcrumb: [
                    {
                        text: "Reindex",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:maintenance:reindex"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
