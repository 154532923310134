export default function (basePath: string) {
    return [
        {
            path: `${basePath}/accesslists`,
            name: "maintenance-accesslists",
            component: () =>
                import("@/apps/maintenance-api/pages/AccessLists/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Access Lists",
                breadcrumb: [
                    {
                        text: "Access Lists",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:accesslists"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/accesslist/:identifier`,
            name: "maintenance-accesslist",
            component: () =>
                import("@/apps/maintenance-api/pages/AccessLists/single/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Access List",
                breadcrumb: [
                    {
                        text: "Access Lists",
                        to: {name: "maintenance-accesslists"},
                        active: false,
                    },
                    {
                        text: "Access List",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:accesslist"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
    ];
}
