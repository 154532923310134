import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = ReportingAPI.Generators;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class GeneratorsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Reporting);
    }

    public async GetList(): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`generators`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Get(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.get(`generator/${id}`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async Create(data: Base.Create): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`generator`, data);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async Update(id: string, data: Base.Update): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.put(`generator/${id}`, data);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async RequestGeneration(id: string, dateFrom: string | null = null, dateTo: string | null = null): Promise<any> {
        try {
            const params = {
                email: true,
                download: false
            };

            if(dateFrom != null)
                params["dateFrom"] = dateFrom;

            if(dateTo != null)
                params["dateTo"] = dateTo;

            const item = await this.Get(id);
            const response: AxiosResponse =
                await this._client.get(`generator/${id}/generate`, {
                    params
                });
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async GenerateDownload(id: string, dateFrom: string | null = null, dateTo: string | null = null): Promise<any> {
        try {
            const params = {
                download: true
            };

            if(dateFrom != null)
                params["dateFrom"] = dateFrom;

            if(dateTo != null)
                params["dateTo"] = dateTo;

            const item = await this.Get(id);
            const response: AxiosResponse =
                await this._client.get(`generator/${id}/generate`, {
                    responseType: "arraybuffer",
                    params
                });

            const type = response.headers['content-type']
            // @ts-ignore
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${item.name} (${dateFrom} - ${dateTo}).xlsx`
            link.click()
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }
}
