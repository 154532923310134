import $store from "@/store";

export async function setupUser(): Promise<void> {
    if(!$store.getters['user/IS_USER_LOGGED_IN'])
        return;
}


export function GetUserIdFromToken(): string {
    const token = $store.getters['user/GET_USERJWT'];
    if(token === null) return null;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const parsed = JSON.parse(jsonPayload);

    return parsed.id;
}