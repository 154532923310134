import $store from "@/store";
import {APP_SELECTOR} from "@/helpers/APP_HELPER";
import ROLE_CHECK from "@/helpers/ROLE_CHECK";

export default function AppMiddleware(to, from, next) {
    if ($store.getters['user/GET_CURRENT_APP'] == null && !['app-selector', 'logout', 'login', 'reporting-report-download'].includes(to.name)) {
        return next({name: 'app-selector'})
    }

    if (Array.isArray(to.meta.app)) {
        if (!to.meta.app.includes($store.getters['user/GET_CURRENT_APP']) && !['app-selector', 'logout', 'login', 'home', 'reporting-report-download'].includes(to.name)) {
            return next({name: 'home'})
        }
    } else {
        if (to.meta.app !== $store.getters['user/GET_CURRENT_APP'] && !['app-selector', 'logout', 'login', 'home', 'reporting-report-download'].includes(to.name)) {
            return next({name: 'home'})
        }
    }


    if (!ROLE_CHECK(to.meta.REQUIRES_ROLES)) {
        $store.commit('user/SET_APP', null);
        return next({name: 'app-selector'})
    }

    if (to.meta.NOT_ALLOWED_WHEN_AUTHORISED) {
        if ($store.getters['user/IS_USER_LOGGED_IN']) {
            return next(APP_SELECTOR)
        }
    }

    next();
}