import store from '@/store';
import ROLE_CHECK from "@/helpers/ROLE_CHECK";
import $store from "@/store";

export default function AuthMiddleware(to, from, next) {
    if (to.name == "login" && $store.getters['user/IS_USER_LOGGED_IN']) {
        return next({
            name: 'app-selector'
        });
    }

    if ($store.getters['user/IS_USER_LOGGED_IN'] && $store.getters['user/GET_JWT'] === 'logging in') {
        $store.commit('user/LOGOUT')
    }
    if ($store.getters['user/IS_USER_LOGGED_IN'] && $store.getters['user/GET_USER'].id == null) {
        $store.dispatch('user/Me');
    }

    if (to.meta.REQUIRES_USER_AUTHORISATION) {
        if (!store.getters['user/IS_USER_LOGGED_IN']) {
            return next({
                name: 'login'
            });
        }
    }

    if (to.meta.REQUIRES_USER_AUTHORISATION && store.getters['user/GET_CURRENT_APP'] == null && to.name !== 'app-selector') {
        return next({
            name: 'app-selector'
        });
    }

    if (to.meta.REQUIRES_ROLES) {
        if (!ROLE_CHECK(to.meta.REQUIRES_ROLES)) {
            return next({
                name: 'error-403'
            });
        }
    }

    return next()
}