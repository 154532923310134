import {GDPRRequestsRepository} from "@/apps/maintenance-api/api/gdprrequests";
import {ClientSettingsRepository} from "@/apps/maintenance-api/api/clientsettings";
import {AccessListsRepository} from "@/apps/maintenance-api/api/accesslists";
import {IdibuAccountsRepository} from "@/apps/maintenance-api/api/idibuaccounts";
import {PlatformsRepository} from "@/apps/maintenance-api/api/platforms";
import {RolesRepository} from "@/apps/maintenance-api/api/roles";
import {VincereInstanceRepository} from "@/apps/maintenance-api/api/vincereinstance";
import {MappableFieldsRepository} from "@/apps/maintenance-api/api/mappableFields";
import {SwaggerRepository} from "@/apps/maintenance-api/api/swagger";
import {DynamicsInstanceRepository} from "@/apps/maintenance-api/api/dynamicsinstance";

export default {
    gdprrequests: new GDPRRequestsRepository(),
    clientsettings: new ClientSettingsRepository(),
    accesslists: new AccessListsRepository(),
    idibuaccounts: new IdibuAccountsRepository(),
    platforms: new PlatformsRepository(),
    roles: new RolesRepository(),
    vincere: new VincereInstanceRepository(),
    dynamics: new DynamicsInstanceRepository(),
    mappableFields: new MappableFieldsRepository(),
    swagger: new SwaggerRepository(),
};
