import Vue from "vue";

Vue.mixin({
  methods: {
    NullOrUndefined(value) {
      return value === undefined || value === null;
    },
  },
});

declare module "vue/types/vue" {
  interface Vue {
    NullOrUndefined(value: any): boolean;
  }
}
