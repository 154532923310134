export default {
  namespaced: true,
  state: {
    title: null,
    lastBreadcrumbTitle: null
  },
  getters: {
    GET_TITLE: state => state.title,
    GET_LAST_BREADCRUMB: state => state.lastBreadcrumbTitle,
  },
  mutations: {
    SET_TITLE(state, val) {
      state.title = val
    },
    SET_LAST_BREADCRUMB(state, val) {
      state.lastBreadcrumbTitle = val
    }
  },
  actions: {},
}
