export default function (basePath: string) {
    return [
        {
            path: `${basePath}/platforms`,
            name: "maintenance-platforms",
            component: () =>
                import("@/apps/maintenance-api/pages/Platforms/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Platforms",
                breadcrumb: [
                    {
                        text: "Platforms",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:platforms"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/platform/:identifier/:action(edit)?`,
            name: "maintenance-platform",
            component: () =>
                import("@/apps/maintenance-api/pages/Platforms/single/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Platform",
                breadcrumb: [
                    {
                        text: "Platforms",
                        to: {name: "maintenance-platforms"},
                        active: false,
                    },
                    {
                        text: "Platform",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:platform"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
