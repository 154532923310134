import $store from "@/store";
import {RawLocation} from "vue-router";

export function APP_SELECTOR(): RawLocation {
    switch ($store.getters["user/GET_CURRENT_APP"]) {
        case "maintenance":
            return {
                name: "maintenance-dashboard",
            };
        case "reporting":
            return {
                name: "reporting-dashboard",
            };
        case "registrations":
            return {
                name: "registrations-dashboard",
            };
        case "employee":
            return {
                name: "employee-dashboard",
            };
        case "candidate":
            return {
                name: "candidate-dashboard",
                params: {
                    platform: "current",
                },
            };
        case "candidate-archive":
            return {
                name: "candidate-dashboard",
                params: {
                    platform: "archive",
                },
            };
        case "swagger":
            return {
                name: "swagger-dashboard",
            };
        default:
            return {
                name: "app-selector",
            };
    }
}

const applicationList = [
    {
        name: "Maintenance",
        app: "maintenance",
        color: "gradient-primary",
        rolesRequired: "maintenance:dashboard",
        to: {},
    },
    {
        name: "Reporting",
        app: "reporting",
        color: "gradient-primary",
        rolesRequired: "reporting:dashboard",
        to: {},
    },
    {
        name: "Registrations",
        app: "registrations",
        color: "gradient-primary",
        rolesRequired: "registrations:dashboard",
        to: {},
    },
    {
        name: "Candidate",
        app: "candidate",
        color: "gradient-primary",
        rolesRequired: "candidate:dashboard",
        to: {},
    },
    {
        name: "Candidate Archive",
        app: "candidate-archive",
        color: "gradient-primary",
        rolesRequired: "candidate:dashboard",
        to: {},
    },
    {
        name: "Employee",
        app: "employee",
        color: "gradient-primary",
        rolesRequired: "employee:dashboard",
        to: {},
    },
    {
        name: "Swagger",
        app: "swagger",
        color: "gradient-primary",
        rolesRequired: "swagger:dashboard",
        to: {},
    },
];

if (process.env.NODE_ENV !== "production") {
    applicationList.splice(4, 1)
}

export const ApplicationList = applicationList;