export default function (basePath: string) {
    return [
        {
            path: `${basePath}/dynamicsinstances`,
            name: "maintenance-dynamicsinstances",
            component: () =>
                import("@/apps/maintenance-api/pages/Dynamics/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Dynamics Instances",
                breadcrumb: [
                    {
                        text: "Dynamics Instances",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:dynamicsinstances"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/dynamicsinstance/:identifier/:action(edit)?`,
            name: "maintenance-dynamicsinstance",
            component: () =>
                import("@/apps/maintenance-api/pages/Dynamics/single/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Dynamics Instance",
                breadcrumb: [
                    {
                        text: "Dynamics Instances",
                        to: {name: "maintenance-dynamicsinstances"},
                        active: false,
                    },
                    {
                        text: "Dynamics Instance",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:dynamicsinstances"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
