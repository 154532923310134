const basePath = "/apps/swagger";

export default [
    {
        path: `${basePath}`,
        name: "swagger-dashboard",
        component: () => import("@/apps/swagger-ui/pages/Index.vue"),
        meta: {
            app: "swagger",
            pageTitle: "Swagger",
            breadcrumb: [
                {
                    text: "Swagger",
                    active: true,
                },
            ],
            REQUIRES_ROLES: ["swagger:dashboard"],
            REQUIRES_USER_AUTHORISATION: true,
            layout: "full-light"
        }
    }
];