import Vue from "vue";
import RoleCheck from "@/helpers/ROLE_CHECK";

Vue.mixin({
    methods: {
        RoleCheck
    }
})

declare module 'vue/types/vue' {
    interface Vue {
        RoleCheck(requiredRoles: string[]): boolean
    }
}
