const basePath = '/apps/employee';

export default [
    {
        path: `${basePath}/dashboard`,
        name: 'employee-dashboard',
        component: () => import('@/apps/employee-api/pages/Dashboard.vue'),
        meta: {
            app: 'employee',
            pageTitle: 'Employee Dashboard',
            breadcrumb: [
                {
                    text: 'Employee Dashboard',
                    active: true,
                },
            ],
            REQUIRES_ROLES: ["employee:dashboard"],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    {
        path: `${basePath}/users`,
        name: 'employee-users',
        component: () => import('@/apps/employee-api/pages/Users/index.vue'),
        meta: {
            app: 'employee',
            pageTitle: 'Users',
            REQUIRES_ROLES: ["employee:users"],
            breadcrumb: [
                {
                    text: 'Employee Dashboard',
                    to: {name: 'employee-dashboard'},
                    active: false,
                },
                {
                    text: 'Users',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    {
        path: `${basePath}/user`,
        name: 'employee-user-create',
        component: () => import('@/apps/employee-api/pages/Users/create.vue'),
        meta: {
            app: 'employee',
            pageTitle: 'Create User',
            REQUIRES_ROLES: ["employee:user:create"],
            breadcrumb: [
                {
                    text: 'Employee Dashboard',
                    to: {name: 'employee-dashboard'},
                    active: false,
                },
                {
                    text: 'Users',
                    to: {name: 'employee-users'},
                    active: false,
                },
                {
                    text: 'Create User',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    {
        path: `${basePath}/user/:identifier`,
        name: 'employee-user',
        component: () => import('@/apps/employee-api/pages/Users/single/index.vue'),
        meta: {
            app: 'employee',
            pageTitle: 'User',
            REQUIRES_ROLES: ["employee:user"],
            breadcrumb: [
                {
                    text: 'Employee Dashboard',
                    to: {name: 'employee-dashboard'},
                    active: false,
                },
                {
                    text: 'Users',
                    to: {name: 'employee-users'},
                    active: false,
                },
                {
                    text: 'User',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    {
        path: `${basePath}/user/:identifier/edit`,
        name: 'employee-user-edit',
        component: () => import('@/apps/employee-api/pages/Users/edit/index.vue'),
        meta: {
            app: 'employee',
            pageTitle: 'User',
            REQUIRES_ROLES: ["employee:user:edit"],
            breadcrumb: [
                {
                    text: 'Employee Dashboard',
                    to: {name: 'employee-dashboard'},
                    active: false,
                },
                {
                    text: 'Users',
                    to: {name: 'employee-users'},
                    active: false,
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        }
    }
]