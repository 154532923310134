import Vue from 'vue';

Vue.mixin({
    methods: {
        /*        SetTitle(title: string, type: string | null = null): void {
                    if(title == null) return;

                    this.$store.commit('base/SET_TITLE', title)

                    let text = title;
                    if(type !== null) {
                        text = `${type} (${title})`
                    }
                    this.$store.commit('base/SET_LAST_BREADCRUMB', text)
                },
                SetBaseTitle(title: string): void {
                    this.$store.commit('base/SET_TITLE', title)
                    this.$store.commit('base/SET_LAST_BREADCRUMB', title)
                },
                SetTitleAndBreadcrumbs(title: string, breadcrumbs: Array<any>) {
                    this.$store.commit('base/SET_TITLE', title)
                    Vue.set(this.$route.meta, 'breadcrumb', breadcrumbs)
                    this.$store.commit('base/SET_LAST_BREADCRUMB', breadcrumbs[breadcrumbs.length - 1].text)
                }*/
    }
})

declare module 'vue/types/vue' {
    interface Vue {
        SetTitle(title: string, type: string | null): void,

        SetBaseTitle(title: string): void,

        SetTitleAndBreadcrumbs(title: string, breadcrumbs: Array<any>): void,
    }
}
