import axios, { AxiosInstance } from "axios";
import store from "@/store";
import $router from "@/router";

function getPlatform(platform: ClientType) {
  let baseURL = process.env.VUE_APP_CANDIDATE_API;

  switch (platform) {
    case ClientType.CandidateCurrent:
      baseURL = process.env.VUE_APP_CANDIDATE_API;
      break;
    case ClientType.CandidateArchive:
      baseURL = process.env.VUE_APP_CANDIDATE_ARCHIVE_API;
      break;
    case ClientType.Employee:
      baseURL = process.env.VUE_APP_EMPLOYEE_API;
      break;
    case ClientType.Maintenance:
      baseURL = process.env.VUE_APP_MAINTENANCE_API;
      break;
    case ClientType.Talent:
      baseURL = process.env.VUE_APP_TALENT_API;
      break;
    case ClientType.Registrations:
      baseURL = process.env.VUE_APP_REGISTRATIONS_API;
      break;
    case ClientType.Reporting:
      baseURL = process.env.VUE_APP_REPORTING_API;
      break;
    case ClientType.EmployeeCrmAccess:
      baseURL = process.env.VUE_APP_EMPLOYEE_CRM_ACCESS_API;
      break;
    case ClientType.RegistrationSendToCrm:
      baseURL = process.env.VUE_APP_REGISTRATION_SEND_TO_CRM_API;
      break;
    case ClientType.Portal:
      baseURL = process.env.VUE_APP_PORTAL_API;
      break;
    case ClientType.Branch:
      baseURL = process.env.VUE_APP_BRANCH_API;
      break;
    case ClientType.Identity:
      baseURL = process.env.VUE_APP_IDENTITY_API;
      break;
  }

  return baseURL;
}

export class BaseRepository {
  baseURL: string;
  constructor(platform: ClientType) {
    this.baseURL = getPlatform(platform);
  }

  get _client(): AxiosInstance {
    const jwt = store.getters["user/GET_JWT"];

    const headers = {
      ClientId: process.env.VUE_APP_CLIENT_ID,
    };

    if (jwt != null && jwt != "logging in") {
      headers["Authentication"] = `Bearer ${jwt}`;
    }

    const a = axios.create({
      baseURL: this.baseURL,
      headers,
    });

    a.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.response.status == 401) {
          //if (process.env.NODE_ENV != "development")
          store.commit("user/LOGOUT");

          return Promise.reject(error.response.data);
        }

        if (error.response.status == 403) {
          //$router.push({ name: "home" });
          return Promise.reject(error.response.data);
        }

        return Promise.reject(error.response.data);
      }
    );

    return a;
  }
}

export enum ClientType {
  CandidateCurrent,
  CandidateArchive,
  Employee,
  Maintenance,
  Talent,
  Registrations,
  Reporting,
  EmployeeCrmAccess,
  RegistrationSendToCrm,
  Portal,
  Branch,
  Identity,
}
