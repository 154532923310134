import Vue from 'vue';
import {email, required} from "@core/utils/validations/validations";

Vue.mixin({
    data() {
        return {
            defaultRules: {
                email,
                required
            }
        }
    }
})