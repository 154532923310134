import {AxiosResponse} from "axios";
import User = EmployeeAPI.User.User;
import ResponseObject = RepositoryTypes.ResponseObject;
import PaginationResponse = RepositoryTypes.PaginationResponse;
import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import UserCreateRequest = EmployeeAPI.User.UserCreateRequest;
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

export class UserRepository extends BaseRepository {
    constructor() {
        super(ClientType.Employee);
    }

    public async Me(): Promise<User> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.get(
                "me"
            );

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async GetList(
        page = 1,
        limit = 10,
        query = undefined
    ): Promise<PaginationResponse<User>> {
        try {
            const req: AxiosResponse<ResponseObject<PaginationResponse<User>>> =
                await this._client.get("users", {
                    params: {
                        page,
                        limit,
                        query,
                    },
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return {totalItems: 0, items: []};
    }

    public async GetByIds(ids: string[]): Promise<User[]> {
        try {
            const req: AxiosResponse<ResponseObject<User[]>> =
                await this._client.post(`users`, {ids});

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Get(id: string): Promise<User> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.get(
                `user/${id}`
            );

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Create(obj: UserCreateRequest): Promise<User> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.post(
                "user",
                obj
            );

            if (req.data.success) {
                NotifySuccess([
                    `User ${obj.firstName} ${obj.lastName} (${obj.email}) Created Successfully`,
                ]);
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
            return null;
        }
    }

    public async Update(
        id: string,
        obj: EmployeeAPI.User.UserUpdateRequest
    ): Promise<User> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.put(
                `user/${id}`,
                obj
            );

            if (req.data.success) {
                const data = req.data.data;
                NotifySuccess([
                    `User ${data.firstName} ${data.lastName} (${data.email}) Updated Successfully`,
                ]);
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
            return null;
        }
    }

    public async ChangePassword(
        id: string,
        password: string,
        passwordRepeat: string
    ): Promise<boolean> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.post(
                `user/${id}/changepassword`,
                {
                    password,
                    passwordRepeat
                }
            );

            if (req.data.success) {
                return true;
            }
        } catch (e) {
            NotifyError(e.messages);
            return false;
        }
    }
}
