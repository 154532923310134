import reporting_generator from "@/apps/reporting-api/router/reporting_generator";
import reporting_template from "@/apps/reporting-api/router/reporting_template";
import reporting_reports from "@/apps/reporting-api/router/reporting_reports";

const basePath = "/apps/reporting";

export default [
    {
        path: `${basePath}/dashboard`,
        name: "reporting-dashboard",
        component: () => import("@/apps/reporting-api/pages/Dashboard.vue"),
        meta: {
            app: "reporting",
            pageTitle: "Reporting Dashboard",
            breadcrumb: [
                {
                    text: "Reporting Dashboard",
                    active: true,
                },
            ],
            REQUIRES_ROLES: ["reporting:dashboard"],
            REQUIRES_USER_AUTHORISATION: true,
        }
    },
    ...reporting_generator(basePath),
    ...reporting_template(basePath),
    ...reporting_reports(basePath)
];
