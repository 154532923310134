import { AxiosResponse } from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;
import CrmAccess = EmployeeAPI.CrmAccess.CrmAccess;
import { NotifyError } from "@/helpers/Toast";
import store from "@/store";
import {BaseRepository} from "@/apps/candidate-api/api/BaseRepository";
import {ClientType} from "@/apps/base/api/BaseRepository";

export class SwaggerRepository extends BaseRepository {
  public Url(): string {
    const jwt = store.getters["user/GET_JWT"];
    return `${this.baseUrl}/documentation/v1/swagger.json?token=${jwt}`;
  }
 }