import {FlowRepository} from "@/apps/registrations-api/api/flow";
import {TaskRepository} from "@/apps/registrations-api/api/task";
import {FlowMappingsRepository} from "@/apps/registrations-api/api/flowmappings";
import {AutoOnboardingsRepository} from "@/apps/registrations-api/api/autoonboardings";
import {MaintenanceRepository} from "@/apps/registrations-api/api/maintenance";
import {SurveysRepository} from "@/apps/registrations-api/api/surveys";
import {RegistrationsRepository} from "@/apps/registrations-api/api/registrations";
import {SwaggerRepository} from "@/apps/registrations-api/api/swagger";

export default {
    flows: new FlowRepository(),
    tasks: new TaskRepository(),
    flowmappings: new FlowMappingsRepository(),
    autoonboarding: new AutoOnboardingsRepository(),
    maintenance: new MaintenanceRepository(),
    surveys: new SurveysRepository(),
    registrations: new RegistrationsRepository(),
    swagger: new SwaggerRepository(),
};
