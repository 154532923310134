export default function (basePath: string) {
    return [
        {
            path: `${basePath}/flows`,
            name: "registration-flows",
            component: () =>
                import("@/apps/registrations-api/pages/Flows/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Flows",
                breadcrumb: [
                    {
                        text: "Flows",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:flows"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
