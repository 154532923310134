export default function (basePath: string) {
    return [
        {
            path: `${basePath}/surveys`,
            name: "registration-surveys",
            component: () =>
                import("@/apps/registrations-api/pages/Surveys/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Surveys",
                breadcrumb: [
                    {
                        text: "Surveys",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:surveys"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/survey/:identifier`,
            name: "registration-survey",
            component: () =>
                import("@/apps/registrations-api/pages/Surveys/single/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Survey",
                breadcrumb: [
                    {
                        text: "Surveys",
                        to: {name: "registration-surveys"},
                    },
                    {
                        text: "Survey",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:surveys"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
