import base from "@/store/base";
import find from "@/apps/candidate-api/router/find";

const basePath = '/apps/candidate/:platform(current|archive)';

export default [
    {
        path: `${basePath}/dashboard`,
        name: 'candidate-dashboard',
        component: () => import('@/apps/candidate-api/pages/Dashboard.vue'),
        meta: {
            app: ['candidate', 'candidate-archive'],
            pageTitle: 'Candidate Dashboard',
            REQUIRES_ROLES: ["candidate:dashboard"],
            breadcrumb: [
                {
                    text: 'Candidate Dashboard',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    {
        path: `${basePath}/users`,
        name: 'candidate-users',
        component: () => import('@/apps/candidate-api/pages/Users/index.vue'),
        meta: {
            app: ['candidate', 'candidate-archive'],
            pageTitle: 'Candidates',
            REQUIRES_ROLES: ["candidate:users"],
            breadcrumb: [
                {
                    text: 'Candidate Dashboard',
                    to: {name: 'candidate-dashboard'},
                    active: false,
                },
                {
                    text: 'Candidates',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    {
        path: `${basePath}/user/:identifier`,
        name: 'candidate-user',
        component: () => import('@/apps/candidate-api/pages/Users/single/index.vue'),
        meta: {
            app: ['candidate', 'candidate-archive'],
            pageTitle: 'User',
            REQUIRES_ROLES: ["candidate:user"],
            breadcrumb: [
                {
                    text: 'Candidate Dashboard',
                    to: {name: 'candidate-dashboard'},
                    active: false,
                },
                {
                    text: 'Users',
                    to: {name: 'candidate-users'},
                    active: false,
                },
                {
                    text: 'User',
                    active: true,
                },
            ],
            REQUIRES_USER_AUTHORISATION: true
        },
    },
    ...find(basePath),
]