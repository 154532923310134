import Vue from "vue";
import VueRouter from "vue-router";
import AuthMiddleware from "@/middleware/user_auth";
import $store from "@/store";

import EmployeeApiRoutes from "@/apps/employee-api/router";
import CandidateApiRoutes from "@/apps/candidate-api/router";
import FlowApiRoutes from "@/apps/registrations-api/router";
import MaintenanceApiRoutes from "@/apps/maintenance-api/router";
import ReportingApiRoutes from "@/apps/reporting-api/router";
import SwaggerRoutes from "@/apps/swagger-ui/router";

import AppMiddleware from "@/middleware/app_middleware";
import { APP_SELECTOR } from "@/helpers/APP_HELPER";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/dashboard",
      alias: "/",
      name: "home",
      meta: {
        REQUIRES_USER_AUTHORISATION: true,
      },
      beforeEnter(to, from, next) {
        if ($store.getters["user/IS_USER_LOGGED_IN"])
          return next(APP_SELECTOR());
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
        NOT_ALLOWED_WHEN_AUTHORISED: true,
      },
    },
    {
      path: "/token",
      name: "token",
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        $store.commit("user/LOGOUT");
        next({ name: "login" });
      },
    },
    {
      path: "/app-selector",
      name: "app-selector",
      component: () => import("@/views/AppSelector.vue"),
      meta: {
        layout: "full",
        REQUIRES_USER_AUTHORISATION: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-403",
      name: "error-403",
      component: () => import("@/views/error/Error403.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    ...EmployeeApiRoutes,
    ...CandidateApiRoutes,
    ...FlowApiRoutes,
    ...MaintenanceApiRoutes,
    ...ReportingApiRoutes,
      ...SwaggerRoutes
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach(AppMiddleware);
router.beforeEach(AuthMiddleware);
router.beforeEach((to, from, next) => {
  if (!to.meta) return;

  if (to.meta.pageTitle) {
    $store.commit("base/SET_TITLE", to.meta.pageTitle);
  }
  /*
            if (to.meta.breadcrumb && to.meta.breadcrumb.length > 0) {
                $store.commit('base/SET_LAST_BREADCRUMB', to.meta.breadcrumb[to.meta.breadcrumb.length - 1].text)
            } else {
                $store.commit('base/SET_LAST_BREADCRUMB', null)
            }*/

  next();
});
export default router;
