export default function (basePath: string) {
    return [
        {
            path: `${basePath}/generators`,
            name: "reporting-generators",
            component: () =>
                import("@/apps/reporting-api/pages/Generators/index.vue"),
            meta: {
                app: "reporting",
                pageTitle: "Report Generators",
                breadcrumb: [
                    {
                        text: "Report Generators",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["reporting:generators"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/generator/:identifier`,
            name: "reporting-generator",
            component: () =>
                import("@/apps/reporting-api/pages/Generators/single/index.vue"),
            meta: {
                app: "reporting",
                pageTitle: "Report Generator",
                breadcrumb: [
                    {
                        text: "Report Generators",
                        to: {name: "reporting-generators"},
                        active: false,
                    },
                    {
                        text: "Report Generator",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["reporting:generator"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
    ]
}