import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = MaintenanceAPI.ClientSettings;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class ClientSettingsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Maintenance);
    }

    public async Clone(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`clientsetting/${id}/clone`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Update(id: string, name: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.put(`clientsetting/${id}`, {
                    name
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async GetList(ids: string[] = null): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`clientsettings`, {
                    params: {
                        ids: (ids == null) ? undefined : ids.join(",")
                    }
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async GetGroupedList(): Promise<Record<string,Item[]>> {
        try {
            const req: AxiosResponse<ResponseObject<Record<string, Item[]>>> =
                await this._client.get(`clientsettings/grouped`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return {};
    }

    public async Get(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`clientsetting`, {id});

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async SetUrl(
        id: string,
        type: string,
        value: string
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`clientsetting/${id}/url/${type}`, {value});

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async SetEmailTemplate(
        id: string,
        type: string,
        value: string
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`clientsetting/${id}/emailtemplate`, {
                    type,
                    value,
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async SetSmsTemplate(
        id: string,
        type: string,
        value: string
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`clientsetting/${id}/smstemplate`, {
                    type,
                    value,
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Create(
        obj: Base.Create
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`clientsettings`, {
                    ...obj,
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}
