import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import { NotifyError } from "@/helpers/Toast";

import ResponseObject = RepositoryTypes.ResponseObject;
import Base = RegistrationsAPI.Registrations;
export class RegistrationsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Registrations);
    }

    public async bulkSend(request: Base.BulkSend): Promise<Base.BulkSendResponse[]> {
        try {
            const req = await this._client.post(`registrations/bulk/send`, request);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}