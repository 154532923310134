export default function (basePath: string) {
  return [
    {
      path: `${basePath}/clientsettings`,
      name: "maintenance-clientsettings",
      component: () =>
        import("@/apps/maintenance-api/pages/ClientSettings/index.vue"),
      meta: {
        app: "maintenance",
        pageTitle: "Client Settings",
        breadcrumb: [
          {
            text: "Client Settings",
            active: true,
          },
        ],
        REQUIRES_ROLES: ["maintenance:clientsettings"],
        REQUIRES_USER_AUTHORISATION: true,
      },
    },
    {
      path: `${basePath}/clientsetting/:identifier/:action(edit)?`,
      name: "maintenance-clientsetting",
      component: () =>
        import("@/apps/maintenance-api/pages/ClientSettings/single/index.vue"),
      meta: {
        app: "maintenance",
        pageTitle: "Client Setting",
        breadcrumb: [
          {
            text: "Client Settings",
            to: { name: "maintenance-clientsettings" },
            active: false,
          },
          {
            text: "Client Setting",
            active: true,
          },
        ],
        REQUIRES_ROLES: ["maintenance:clientsetting"],
        REQUIRES_USER_AUTHORISATION: true,
      },
    },
  ];
}
