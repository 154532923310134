import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import basicSearch from "@/apps/candidate-api/api/queries/basicSearch";
import { NotifyError } from "@/helpers/Toast";
import ResponseObject = RepositoryTypes.ResponseObject;

export class TaskRepository extends BaseRepository {
  UseMockData = false;

  constructor() {
    super(ClientType.Registrations);

    if (process.env.NODE_ENV === "development") {
      this.UseMockData = true;
    }
  }

  public async GetTasks(
    ids: Array<string>
  ): Promise<Array<RegistrationsAPI.Tasks.Task>> {
    try {
      const req: AxiosResponse<
        ResponseObject<Array<RegistrationsAPI.Tasks.Task>>
      > = await this._client.post("tasks", { ids });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }
  }
}
