import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import { NotifyError } from "@/helpers/Toast";

import GDPRRequests = MaintenanceAPI.GDPRRequests;
import ResponseObject = RepositoryTypes.ResponseObject;

export class FlowMappingsRepository extends BaseRepository {
  constructor() {
    super(ClientType.Registrations);
  }

  public async Get(
    type: "task" | "question",
    ids: string[]
  ): Promise<RegistrationsAPI.FlowMappings.FlowMapping[]> {
    try {
      const req: AxiosResponse<
        ResponseObject<RegistrationsAPI.FlowMappings.FlowMapping[]>
      > = await this._client.post(`flowmappings`, {
        type,
        ids: ids.join(","),
      });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return [];
  }

  public async Create(
    obj: RegistrationsAPI.FlowMappings.CreateFlowMapping
  ): Promise<RegistrationsAPI.FlowMappings.FlowMapping> {
    try {
      const req: AxiosResponse<
        ResponseObject<RegistrationsAPI.FlowMappings.FlowMapping>
      > = await this._client.post(`flowmapping`, {
        ...obj,
      });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return null;
  }
}
