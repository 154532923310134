import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import { NotifyError } from "@/helpers/Toast";

import ResponseObject = RepositoryTypes.ResponseObject;
import FlowCollection = RegistrationsAPI.Flows.FlowCollection;
import Flow = RegistrationsAPI.Flows.Flow;

export class FlowRepository extends BaseRepository {
  constructor() {
    super(ClientType.Registrations);
  }

  public async GetFlowContainers(): Promise<Array<FlowCollection>> {
    try {
      const resp: AxiosResponse<ResponseObject<Array<FlowCollection>>> =
          await this._client.get(`flow-containers`, {

          });

      if (resp.data.success) {
        return resp.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }
  }
  
  public async GetFlowContainer(containerId: string, withTasks = false, withExternalBranchIds = false, reindex = false): Promise<FlowCollection> {
    try {
      const resp: AxiosResponse<ResponseObject<FlowCollection>> =
          await this._client.get(`flow-container/${containerId}`, {
            params: {
              withTasks,
              withExternalBranchIds,
              reindex
            }
          });

      if (resp.data.success) {
        return resp.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }
  }
  
  public async GetFlows(withTasks = false, withExternalBranchIds = false, reindex = false): Promise<Array<FlowCollection>> {
    try {
      const resp: AxiosResponse<ResponseObject<Array<FlowCollection>>> =
        await this._client.get("flows", {
          params: {
            withTasks,
            withExternalBranchIds,
            reindex
          }
        });

      if (resp.data.success) {
        return resp.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }
  }

  public async GetFlow(id: string, withTasks = false, withExternalBranchIds = false, reindex = false): Promise<Flow> {
    try {
      const resp: AxiosResponse<ResponseObject<Flow>> =
          await this._client.get(`flow/${id}`, {
            params: {
              withTasks,
              withExternalBranchIds,
              reindex
            }
          });

      if (resp.data.success) {
        return resp.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }
  }

  public async ReindexById(id: string): Promise<boolean> {
    try {
      const resp: AxiosResponse<ResponseObject<boolean>> =
          await this._client.get(`reindex/${id}`, {

          });

      if (resp.data.success) {
        return resp.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }
  }
}
