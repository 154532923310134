import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;

import Base = RegistrationsAPI.Surveys;
import Item = Base.Item;
import {NotifyError} from "@/helpers/Toast";

export class SurveysRepository extends BaseRepository {
    constructor() {
        super(ClientType.Registrations);
    }

    public async GetList(): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`surveys`, {
                    params: {},
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Get(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.get(`survey/${id}`, {
                    params: {},
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Send(id: string, invites: Base.SurveyInvite[], overrideContent: Base.ContentOverride = null): Promise<boolean> {
        try {
            const req: AxiosResponse<ResponseObject<boolean>> =
                await this._client.post(`survey/${id}/send`, {
                    invites,
                    overrideContent,
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}
