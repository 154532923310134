export default function (basePath: string) {
    return [
        {
            path: `${basePath}/templates`,
            name: "reporting-templates",
            component: () =>
                import("@/apps/reporting-api/pages/Templates/index.vue"),
            meta: {
                app: "reporting",
                pageTitle: "Report Templates",
                breadcrumb: [
                    {
                        text: "Report Templates",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["reporting:templates"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/template/:identifier`,
            name: "reporting-template",
            component: () =>
                import("@/apps/reporting-api/pages/Templates/single/index.vue"),
            meta: {
                app: "reporting",
                pageTitle: "Report Template",
                breadcrumb: [
                    {
                        text: "Report Templates",
                        to: {name: "reporting-templates"},
                        active: false,
                    },
                    {
                        text: "Report Template",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["reporting:templates"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
    ]
}