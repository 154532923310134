import axios, { AxiosInstance } from "axios";
import store from "@/store";
import {ClientType} from "@/apps/base/api/BaseRepository";

function getPlatform(platform: ClientType) {
  let baseURL = process.env.VUE_APP_CANDIDATE_API;

  switch (platform) {
    case ClientType.CandidateCurrent:
      baseURL = process.env.VUE_APP_CANDIDATE_API;
      break;
    case ClientType.CandidateArchive:
      baseURL = process.env.VUE_APP_CANDIDATE_ARCHIVE_API;
      break;
  }

  return baseURL;
}

export class BaseRepository {
  _platform: ClientType | null = null;
  constructor(platform: ClientType = null) {
    this._platform = platform;
  }

  get baseUrl() {
    let baseURL = getPlatform(ClientType.CandidateCurrent);
    if (this._platform != null) {
      baseURL = getPlatform(this._platform);
    } else {
      if (
        store.getters["user/GET_CURRENT_APP"] == "candidate" ||
        store.getters["user/GET_CURRENT_APP"] == "candidate-archive"
      ) {
        baseURL = getPlatform(
          store.getters["user/GET_CURRENT_APP"] == "candidate-archive"
            ? ClientType.CandidateArchive
            : ClientType.CandidateCurrent
        );
      }
    }

    return baseURL;
  }
  get _client(): AxiosInstance {
    const jwt = store.getters["user/GET_JWT"];

    const headers = {
      ClientId: process.env.VUE_APP_CLIENT_ID,
    };

    if (jwt != null && jwt != "logging in") {
      headers["Authentication"] = `Bearer ${jwt}`;
    }

    return axios.create({
      baseURL: this.baseUrl,
      headers,
    });
  }
}