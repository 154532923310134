import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = ReportingAPI.Reports;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class ReportsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Reporting);
    }

    public async GetList(page = 1, limit = 10): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`reports`, {
                    params: {
                        page: page,
                        limit: limit,
                    }
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e?.messages?.includes("Not Found")) return null;

            NotifyError(e?.messages);
        }

        return null;
    }

    public async Get(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.get(`report/${id}`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async Download(id: string): Promise<void> {
        try {
            const item = await this.Get(id);
            const response: AxiosResponse =
                await this._client.get(`report/${id}?download=true`);

            if(response.status == 200) {
               /* const type = response.headers['content-type']
                // @ts-ignore
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })*/
                const link = document.createElement('a')
                link.href = `${process.env.VUE_APP_REPORTING_API}/report/${id}?download=true`
                link.download = `${process.env.VUE_APP_REPORTING_API}/report/${id}?download=true`
                link.click()
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }
}
