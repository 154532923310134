export default function (basePath: string) {
    return [
        {
            path: `${basePath}/reports`,
            name: "reporting-reports",
            component: () =>
                import("@/apps/reporting-api/pages/Reports/index.vue"),
            meta: {
                app: "reporting",
                pageTitle: "Reports",
                breadcrumb: [
                    {
                        text: "Reports",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["reporting:reports"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `/report/download/:identifier`,
            name: "reporting-report-download",
            component: () =>
                import("@/apps/reporting-api/pages/Reports/Download.vue"),
            meta: {
                layout: "full",
                REQUIRES_USER_AUTHORISATION: false,
            },
        },
    ]
}