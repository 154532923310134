import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;
import SearchResultItem = EmployeeAPI.Azure.Directory.SearchResultItem;
import { NotifyError } from "@/helpers/Toast";

export class AzureRepository extends BaseRepository {
  constructor() {
    super(ClientType.Employee);
  }

  async graphDirectorySearch(query: string): Promise<SearchResultItem[]> {
    try {
      const req: AxiosResponse<ResponseObject<SearchResultItem[]>> =
        await this._client.get("azure/graph/directory/search", {
          params: {
            query,
          },
        });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return [];
  }
}
