import {BaseRepository} from "./BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError} from "@/helpers/Toast";
import ResponseObject = RepositoryTypes.ResponseObject;
// @ts-ignore
import SearchItem = CandidateAPI.Candidate.SearchItem;
// @ts-ignore
import User = CandidateAPI.Candidate.User;
import basicSearch from "@/apps/candidate-api/api/queries/basicSearch";
import SearchResponse = RepositoryTypes.SearchResponse;

export class UserRepository extends BaseRepository {
    public async BasicSearch(
        query: string = null,
        page = 1,
        limit = 10
    ): Promise<SearchResponse<SearchItem>> {
        let sortBy: any = [
            {
                _score: {
                    order: "desc",
                },
            },
        ];

        if (query == null) {
            sortBy = [
                {
                    createdAt: {
                        order: "desc",
                    },
                },
            ];
        }

        try {
            const req: AxiosResponse<ResponseObject<SearchResponse<SearchItem>>> =
                await this._client.post("search/directtoelastic", {
                    page,
                    size: limit,
                    query: basicSearch(query),
                    sortBy,
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return {
            totalItems: 0,
            items: [],
            totalPages: 1,
            nextPage: {page: 1, size: 25},
            maxScore: 0,
        };
    }

    public async AcodeSearch(
        acodes: string[] = []
    ): Promise<SearchResponse<SearchItem>> {
        try {
            const req: AxiosResponse<ResponseObject<SearchResponse<SearchItem>>> =
                await this._client.post("search/directtoelastic", {
                    page: 1,
                    size: acodes.length,
                    query: {
                        "bool": {
                            "should": [
                                {
                                    "nested": {
                                        "path": "registrations",
                                        "query": {
                                            "terms": {
                                                "registrations.acode": acodes
                                            }

                                        }
                                    }
                                }
                            ],
                            "minimum_should_match": 1
                        }
                    },
                    sortBy: [
                        {
                            _score: {
                                order: "desc",
                            }
                        }
                    ]
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return {
            totalItems: 0,
            items: [],
            totalPages: 1,
            nextPage: {page: 1, size: 25},
            maxScore: 0,
        };
    }

    public async Get(id: string, includes: string[] = []): Promise<User> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.get(
                `user/${id}`,
                {
                    params: {
                        includes: includes.join(","),
                    },
                }
            );

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async GetByIds(ids: string[]): Promise<User[]> {
        try {
            const req: AxiosResponse<ResponseObject<SearchResponse<User>>> =
                await this._client.post(`/search/byids`, {
                    ids: ids,
                    page: 1,
                    limit: ids.length + 1,
                });

            if (req.data.success) {
                return req.data.data.items;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async GetByEmailAddress(emailAddress: string): Promise<User> {
        try {
            const req: AxiosResponse<ResponseObject<User>> = await this._client.post(
                `/search/email`,
                {
                    value: emailAddress,
                }
            );

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}
