import { BaseRepository } from "./BaseRepository";
import { AxiosResponse } from "axios";
import { NotifyError } from "@/helpers/Toast";
import ResponseObject = RepositoryTypes.ResponseObject;

export class ComplianceRepository extends BaseRepository {
  public async GDPRRemoval(id: string): Promise<boolean> {
    try {
      const req: AxiosResponse<ResponseObject<boolean>> =
        await this._client.post(`compliance/gdpr`, {
          UserId: id,
        });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return null;
  }

  public async GDPRRemovalRevoke(email: string, employeeId: string): Promise<boolean> {
    try {
      const req: AxiosResponse<ResponseObject<boolean>> =
          await this._client.post(`compliance/gdpr/revoke`, {
            Email: email,
            EmployeeId: employeeId
          });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return null;
  }

  public async GDPRRemovalCheck(
    emailAddress: string
  ): Promise<{ gdprRemoved: boolean; userFound: boolean; digitalRegistrationCount: number; id: string; gdprRevoked: boolean; gdprRevokedDate: boolean | null; gdprRevokedEmployee: string}> {
    try {
      const req: AxiosResponse<
        ResponseObject<{ gdprRemoved: boolean; userFound: boolean; digitalRegistrationCount: number; id: string; gdprRevoked: boolean; gdprRevokedDate: boolean | null; gdprRevokedEmployee: string}>
      > = await this._client.post(`compliance/gdpr/check`, {
        emailAddress,
      });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return null;
  }
}
