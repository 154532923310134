import { BaseRepository, ClientType } from "@/apps/base/api/BaseRepository";
import { AxiosResponse } from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;
import CrmAccess = EmployeeAPI.CrmAccess.CrmAccess;
import { NotifyError } from "@/helpers/Toast";

export class MaintenanceRepository extends BaseRepository {
  constructor() {
    super(ClientType.Employee);
  }

  public async GetDashboard(): Promise<EmployeeAPI.Maintenance.Dashboard> {
    try {
      const req: AxiosResponse<
        ResponseObject<EmployeeAPI.Maintenance.Dashboard>
      > = await this._client.get(`maintenance/dashboard`, {
        params: {},
      });

      if (req.data.success) {
        return req.data.data;
      }
    } catch (e) {
      NotifyError(e.messages);
    }

    return { employeeCount: 0, activityTracked: 0 };
  }
}
