import { UserRepository } from "@/apps/candidate-api/api/user";
import { ClientType } from "@/apps/base/api/BaseRepository";

import { ComplianceRepository } from "@/apps/candidate-api/api/compliance";
import { DashboardRepository } from "@/apps/candidate-api/api/dashboard";
import {SwaggerRepository} from "@/apps/candidate-api/api/swagger";

export default function (platform: ClientType = null) {
  return {
    dashboard: new DashboardRepository(platform),
    user: new UserRepository(platform),
    compliance: new ComplianceRepository(platform),
    swagger: new SwaggerRepository(platform),
  };
}
