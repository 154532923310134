import {GeneratorsRepository} from "@/apps/reporting-api/api/generators";
import {TemplatesRepository} from "@/apps/reporting-api/api/templates";
import {ReportsRepository} from "@/apps/reporting-api/api/reports";
import {SwaggerRepository} from "@/apps/reporting-api/api/swagger";

export default {
    generators: new GeneratorsRepository(),
    templates: new TemplatesRepository(),
    reports: new ReportsRepository(),
    swagger: new SwaggerRepository(),
};