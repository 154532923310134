import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)


import {ValidationObserver, ValidationProvider} from "vee-validate";

Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);

import {BRow, BCol} from "bootstrap-vue";

Vue.component(BRow.name, BRow);
Vue.component(BCol.name, BCol);

import "@/libs/vue-select"