import Vue from "vue";
import { vueSet } from "vue-deepset";

Vue.mixin({
  methods: {
    DeepSet(object: any, prop: string, value: any) {
      return vueSet(object, prop, value)
    },
  },
});

declare module "vue/types/vue" {
  interface Vue {
    DeepSet(object: any, prop: string, value: any): boolean;
  }
}
