import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = ReportingAPI.Templates;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class TemplatesRepository extends BaseRepository {
    constructor() {
        super(ClientType.Reporting);
    }

    public async GetTypeList(): Promise<Dictionary<string>> {
        try {
            const req: AxiosResponse<ResponseObject<Dictionary<string>>> =
                await this._client.get(`template-types`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return {}
        ;
    }

    public async GetList(): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`templates`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Get(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.get(`template/${id}`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async Create(data: Base.Create): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`template`, data);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async Update(id: string, data: Base.Update): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.put(`template/${id}`, data);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if (e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }
}
