import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export function NotifyError(
  notifyMessage: Array<string>,
  timeout = 3000
): void {
  for (const i in notifyMessage) {
    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: notifyMessage[i].substring(0, 200),
          variant: "error",
        },
      },
      { timeout }
    );
  }
}

export function NotifySuccess(
  notifyMessage: Array<string>,
  timeout = 3000
): void {
  for (const i in notifyMessage) {
    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: notifyMessage[i].substring(0, 200),
          variant: "success",
        },
      },
      { timeout }
    );
  }
}

export function NotifyInfo(notifyMessage: Array<string>, timeout = 3000): void {
  for (const i in notifyMessage) {
    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: notifyMessage[i].substring(0, 200),
          variant: "info",
        },
      },
      { timeout }
    );
  }
}
