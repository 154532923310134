import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = MaintenanceAPI.AccessLists;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class AccessListsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Maintenance);
    }

    public async GetList(): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`accesslists`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Get(type: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.get(`accesslist/${type}`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            if(e.messages.includes("Not Found")) return null;

            NotifyError(e.messages);
        }

        return null;
    }

    public async Set(
        type: string,
        name: string,
        whitelist: string[],
        blacklist: string[],
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`accesslist/${type}`, {
                    name, whitelist, blacklist
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}
