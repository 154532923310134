import gdprrequests from "@/apps/maintenance-api/router/gdprrequests";
import clientsettings from "@/apps/maintenance-api/router/clientsettings";
import accessLists from "@/apps/maintenance-api/router/accessLists";
import idibuaccounts from "@/apps/maintenance-api/router/idibuaccounts";
import platforms from "@/apps/maintenance-api/router/platforms";
import roles from "@/apps/maintenance-api/router/roles";
import vincere from "@/apps/maintenance-api/router/vincere";
import candidate from "@/apps/maintenance-api/router/candidate";
import dynamics from "@/apps/maintenance-api/router/dynamics";

const basePath = "/apps/maintenance";

export default [
    {
        path: `${basePath}/dashboard`,
        name: "maintenance-dashboard",
        component: () => import("@/apps/maintenance-api/pages/Dashboard.vue"),
        meta: {
            app: "maintenance",
            pageTitle: "Maintenance Dashboard",
            breadcrumb: [
                {
                    text: "Maintenance Dashboard",
                    active: true,
                },
            ],
            REQUIRES_ROLES: ["maintenance:dashboard"],
            REQUIRES_USER_AUTHORISATION: true,
        },
    },
    ...gdprrequests(basePath),
    ...clientsettings(basePath),
    ...accessLists(basePath),
    ...idibuaccounts(basePath),
    ...platforms(basePath),
    ...roles(basePath),
    ...vincere(basePath),
    ...dynamics(basePath),
    ...candidate(basePath),
];
