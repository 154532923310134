export default function (basePath: string) {
    return [
        {
            path: `${basePath}/autoonboardings`,
            name: "registration-autoonboardings",
            component: () =>
                import("@/apps/registrations-api/pages/AutoOnboarding/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Auto Onboarding",
                breadcrumb: [
                    {
                        text: "Auto Onboarding",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["registrations:autoonboardings"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/autoonboarding/:identifier`,
            name: "registration-autoonboarding",
            component: () =>
                import("@/apps/registrations-api/pages/AutoOnboarding/single/index.vue"),
            meta: {
                app: "registrations",
                pageTitle: "Auto Onboarding",
                breadcrumb: [
                    {
                        text: "Auto Onboarding",
                        to: {name: "registration-autoonboardings"},
                        active: false,
                    }
                ],
                REQUIRES_ROLES: ["registrations:autoonboarding"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
    ];
}
