import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import ResponseObject = RepositoryTypes.ResponseObject;

import Base = RegistrationsAPI.AutoOnboardings;
import Item = Base.AutoOnboarding;
import {NotifyError} from "@/helpers/Toast";

export class AutoOnboardingsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Registrations);
    }

    public async GetList(): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`autoonboardings`, {
                    params: {},
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Get(id: string): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.get(`autoonboarding/${id}`, {
                    params: {},
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Create(body: Base.CreateAutoOnboarding): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`autoonboarding`, {
                    ...body
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Update(id: string, body: Base.UpdateAutoOnboarding): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.put(`autoonboarding/${id}`, {
                    ...body
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}
