export default function (basePath: string) {
  return [
    {
      path: `${basePath}/gdprrequests`,
      name: "maintenance-gdprrequests",
      component: () =>
        import("@/apps/maintenance-api/pages/GdprRequests/index.vue"),
      meta: {
        app: "maintenance",
        pageTitle: "GDPR Requests",
        breadcrumb: [
          {
            text: "GDPR Requests",
            active: true,
          },
        ],
        REQUIRES_ROLES: ["maintenance:gdprrequests"],
        REQUIRES_USER_AUTHORISATION: true,
      },
    },
  ];
}
