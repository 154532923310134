import autoOnboarding from "@/apps/registrations-api/router/autoOnboarding";
import maintenance from "@/apps/registrations-api/router/maintenance";
import surveys from "@/apps/registrations-api/router/surveys";
import registrations from "@/apps/registrations-api/router/registrations";
import flows from "@/apps/registrations-api/router/flows";

const basePath = "/apps/registration";

export default [
    {
        path: `${basePath}/dashboard/:collection?/:flow?/:task?`,
        name: "registrations-dashboard",
        component: () => import("@/apps/registrations-api/pages/Dashboard.vue"),
        meta: {
            app: "registrations",
            pageTitle: "Flow Dashboard",
            breadcrumb: [
                {
                    text: "Flow Dashboard",
                    active: true,
                },
            ],
            REQUIRES_ROLES: ["registrations:dashboard"],
            REQUIRES_USER_AUTHORISATION: true,
        },
        children: [
/*            {
                path: `${basePath}/dashboard/:collection?/:flow?/:task?`,
                name: "registrations-flow-collection-dashboard",
                meta: {
                    app: "registrations",
                    pageTitle: "Flow Dashboard",
                    breadcrumb: [
                        {
                            text: "Flow Dashboard",
                            active: true,
                        },
                    ],
                },
            },*/
/*            {
                path: `${basePath}/dashboard/:collection/:flow`,
                name: "registrations-flow-registrations-dashboard",
                meta: {
                    app: "registrations",
                    pageTitle: "Flow Dashboard",
                    breadcrumb: [
                        {
                            text: "Flow Dashboard",
                            active: true,
                        },
                    ],
                },
            },
            {
                path: `${basePath}/dashboard/:collection/:flow/:task`,
                name: "registrations-registrations-flow-task-dashboard",
                meta: {
                    app: "registrations",
                    pageTitle: "Flow Dashboard",
                    breadcrumb: [
                        {
                            text: "Flow Dashboard",
                            active: true,
                        },
                    ],
                },
            },*/
        ],
    },
    {
        path: `${basePath}/reporting`,
        name: "registration-flow-reporting",
        component: () =>
            import("@/apps/registrations-api/pages/Reporting/index.vue"),
        meta: {
            app: "registrations",
            pageTitle: "Flow Reporting",
            breadcrumb: [
                {
                    text: "Flow Reporting",
                    active: true,
                },
            ],
            REQUIRES_ROLES: ["registrations:reporting"],
            REQUIRES_USER_AUTHORISATION: true,
        },
    },
    ...autoOnboarding(basePath),
    ...maintenance(basePath),
    ...surveys(basePath),
    ...registrations(basePath),
    ...flows(basePath),
];
