import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate"

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import base from './base'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        base,
        user
    },
    // @ts-ignore
    strict: process.env.DEV,
    plugins: [createPersistedState()],
});
