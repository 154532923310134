export default function (basePath: string) {
    return [
        {
            path: `${basePath}/candidate/additionalfields`,
            name: "maintenance-candidate-additionalfields",
            component: () =>
                import("@/apps/maintenance-api/pages/Candidate/AdditionalFields/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Candidate API - Additional Fields",
                breadcrumb: [
                    {
                        text: "Candidate API - Additional Fields",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:surveys"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        }
    ];
}
