export default function (basePath: string) {
    return [
        {
            path: `${basePath}/idibuaccounts`,
            name: "maintenance-idibuaccounts",
            component: () =>
                import("@/apps/maintenance-api/pages/IdibuAccounts/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Idibu Accounts",
                breadcrumb: [
                    {
                        text: "Idibu Accounts",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:idibuaccounts"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
        {
            path: `${basePath}/idibuaccount/:identifier`,
            name: "maintenance-idibuaccount",
            component: () =>
                import("@/apps/maintenance-api/pages/IdibuAccounts/single/index.vue"),
            meta: {
                app: "maintenance",
                pageTitle: "Idibu Account",
                breadcrumb: [
                    {
                        text: "Idibu Accounts",
                        to: {name: "maintenance-idibuaccounts"},
                        active: false,
                    },
                    {
                        text: "Idibu Account",
                        active: true,
                    },
                ],
                REQUIRES_ROLES: ["maintenance:idibuaccount"],
                REQUIRES_USER_AUTHORISATION: true,
            },
        },
    ];
}
