import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = MaintenanceAPI.MappableFields;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class MappableFieldsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Maintenance);
    }

    public async GetBySystem(system: string): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`mappablefields`, {
                    params: {
                        system
                    }
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Create(body: Base.Create
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`mappablefield`, {
                    ...body
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Update(id: string, body: Base.Update
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.put(`mappablefield/${id}`, {
                    ...body
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }
}
