import Vue from "vue";
import employee from "@/apps/employee-api/api";
import employeecrmaccess from "@/apps/employee-crm-access-api/api";
import maintenance from "@/apps/maintenance-api/api";
import candidate from "@/apps/candidate-api/api";
import talent from "@/apps/talent-api/api";
import registrations from "@/apps/registrations-api/api";
import reporting from "@/apps/reporting-api/api";
import registrationsendtocrm from "@/apps/registration-send-to-crm-api/api";
import portal from "@/apps/portal-api/api";
import branch from "@/apps/branch-api/api";
import identity from "@/apps/identity-api/api";

import { ClientType } from "@/apps/base/api/BaseRepository";

const $api = {
  employee,
  maintenance,
  candidate: candidate(),
  candidateCurrent: candidate(ClientType.CandidateCurrent),
  candidateArchive: candidate(ClientType.CandidateArchive),
  talent,
  registrations,
  reporting,
  employeecrmaccess,
  registrationsendtocrm,
  portal,
  branch,
  identity
};

export default $api;

Vue.prototype.$api = $api;
declare module "vue/types/vue" {
  interface Vue {
    $api: typeof $api;
  }
}
