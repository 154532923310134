import {BaseRepository, ClientType} from "@/apps/base/api/BaseRepository";
import {AxiosResponse} from "axios";
import {NotifyError, NotifySuccess} from "@/helpers/Toast";

import Base = MaintenanceAPI.GDPRRequests;
import Item = Base.Item;
import ResponseObject = RepositoryTypes.ResponseObject;

export class GDPRRequestsRepository extends BaseRepository {
    constructor() {
        super(ClientType.Maintenance);
    }

    public async GetList(): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.get(`gdprrequests`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return [];
    }

    public async Count(): Promise<number> {
        try {
            const req: AxiosResponse<ResponseObject<number>> = await this._client.get(
                `gdprrequests/count`
            );

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return 0;
    }

    public async Create(
        obj: Base.Create
    ): Promise<Item> {
        try {
            const req: AxiosResponse<ResponseObject<Item>> =
                await this._client.post(`gdprrequest`, {
                    ...obj,
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async Delete(id: string): Promise<boolean> {
        try {
            const req: AxiosResponse<ResponseObject<boolean>> =
                await this._client.delete(`gdprrequest/${id}`);

            if (req.data.success) {
                NotifySuccess(["GDPR Request Fulfilled"]);
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async BulkCreate(
        emails: string[]
    ): Promise<Item[]> {
        try {
            const req: AxiosResponse<ResponseObject<Item[]>> =
                await this._client.post(`gdprrequests`, {
                    Emails: emails
                });

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return null;
    }

    public async ProcessAll(): Promise<boolean> {
        try {
            const req: AxiosResponse<ResponseObject<boolean>> =
                await this._client.post(`gdprrequests/process`);

            if (req.data.success) {
                return req.data.data;
            }
        } catch (e) {
            NotifyError(e.messages);
        }

        return false;
    }
}
