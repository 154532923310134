export default function(query) {
    if(query == null)
        return {
            "query_string": {
                "query": "*"
            }
        }
    return {
        "bool": {
            "should": [
                {
                    "nested": {
                        "path": "emails",
                        "query": {
                            "multi_match": {
                                "query": query,
                                "type": "cross_fields",
                                "fields": [
                                    "emails.emailAddress^2"
                                ],
                                "slop": 1
                            }
                        }
                    }
                },
                {
                    "nested": {
                        "path": "phoneNumbers",
                        "query": {
                            "multi_match": {
                                "query": query,
                                "type": "cross_fields",
                                "fields": [
                                    "phoneNumbers.phoneNumber^2"
                                ],
                                "slop": 1
                            }
                        }
                    }
                },
                {
                    "nested": {
                        "path": "user",
                        "query": {
                            "multi_match": {
                                "query": query,
                                "type": "cross_fields",
                                "fields": [
                                    "user.firstName",
                                    "user.lastName"
                                ],
                                "slop": 1
                            }
                        }
                    }
                }
            ],
            "minimum_should_match":1
        }
    }
}